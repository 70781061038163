import _ from 'lodash';
window._ = _;
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ForgeBanner, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { DatasetPreview } from './components/DatasetPreview';
import { AudienceScope, View } from 'common/types/view';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { ApprovalState, SubmissionObject } from 'common/core/approvals_enums';

const t = (k: string, scope = 'dataset_landing_page.dataset_preview.widget_mode') => I18n.t(k, { scope });

const fetchRowCount = (view: View) => {
  const soqlDataProvider = new SoqlDataProvider(
    { domain: window.location.hostname, datasetUid: view.id },
    true
  );

  return soqlDataProvider
    .getRowCount()
    .then((resp) => {
      return resp;
    })
    .catch((e) => console.error('Error fetching row count', e));
};

export const DataPreviewWidgetWrapper = () => {
  const view = window.dataWidgetInitialState!.view;
  const rowLabel = window.dataWidgetInitialState!.rowLabel;
  const rowLabelMultiple = window.dataWidgetInitialState!.rowLabelMultiple;
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);

  if (!rowCount) {
    fetchRowCount(view).then((count) => {
      if (count) {
        setRowCount(count);
      }
    });
  }

  const shouldShowPrivateAssetWarning = (() => {
    // if the user doesn't have permissions they won't even be able to see the widget or this banner.
    if (view.permissions && view.permissions?.scope !== AudienceScope.Public) {
      return true;
    }

    // Even if the scope is public, the view isn't *actually* public until it's approved as well.
    const isApproved = view.approvals.some((approval) => {
      return (
        approval.submissionObject === SubmissionObject.PUBLIC_AUDIENCE_REQUEST &&
        approval.state === ApprovalState.APPROVED
      );
    });
    return !isApproved;
  })();

  return (
    <div className="data-preview-widget-mode">
      {shouldShowPrivateAssetWarning && (
        <ForgeBanner id="private-asset-embed-banner" theme="warning" canDismiss={false}>
          <ForgeIcon slot="icon" name="alert" />
          <span className="private-asset-embed-banner-message">
            {t('private_asset_embed_banner_message')}
          </span>
        </ForgeBanner>
      )}
      <DatasetPreview
        view={view}
        rowLabel={rowLabel}
        rowLabelMultiple={rowLabelMultiple}
        rowCount={rowCount}
        isWidget={true}
      />
    </div>
  );
};

// Don't trigger this if this is imported in the test file
if (document.querySelector('#data-tab-widget')) {
  ReactDOM.render(<DataPreviewWidgetWrapper />, document.querySelector('#data-tab-widget'));
}
